<template>
  <div class="answer-sheet">
    <div class="screen clearfix">
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="screen.company" placeholder="输入企业名称" style="width: 240px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>所在地区</span>
        <div class="box">
          <el-cascader
              placeholder="请选择组织机构所在地区" style="width: 180px;"
              v-model="addressData"
              :options="dictData.addressData"
              :props="{ label: 'name', value: 'id',multiple: true }"
              collapse-tags
              ref="cascader"
              @change="handleChange"></el-cascader>
        </div>
      </div>
      <div class="item">
        <span>所属网格</span>
        <div class="box">
          <el-select v-model="screen.grid_id" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.gridOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>填报问卷</span>
        <div class="box">
          <el-input v-model="screen.name" placeholder="输入问卷名称" style="width: 180px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>商企经理</span>
        <div class="box">
          <el-select v-model="screen.admin_id" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in dictData.businessManagerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span class="button inquire" @click="search">查询</span>
      <span class="button" @click="resetQuery">重置</span>
    </div>
    <el-table
        ref="multipleTable"
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="60"
          align="center">
      </el-table-column>
<!--      <el-table-column-->
<!--          label="星标"-->
<!--          width="70"-->
<!--          align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <img v-if="scope.row.star" @click="handleStarChange(scope.$index)" class="star"-->
<!--               src="@/assets/answerSheet/star_orange_icon@2x.png" alt=""/>-->
<!--          <img v-else class="star" @click="handleStarChange(scope.$index)"-->
<!--               src="@/assets/answerSheet/star_grey_icon@2x.png" alt=""/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="companyName"
          label="企业名称"
          min-width="100%">
      </el-table-column>
      <el-table-column
          prop="area_name"
          label="所在地区">
      </el-table-column>
      <el-table-column
          prop="gridName"
          label="所属网格">
      </el-table-column>
      <el-table-column
          prop="title"
          label="填报问卷"
          min-width="100%">
      </el-table-column>
      <el-table-column
          prop="truename"
          label="商企经理">
        <template slot-scope="scope">
          <span>{{ scope.row.account }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="完整度"
          width="100"
          align="center">
        <template slot-scope="scope">
          <span class="color-red">{{ scope.row.integrity }}%</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="formattedTime"
          label="提交时间"
          width="150">
      </el-table-column>
      <el-table-column
          label="操作"
          width="170">
        <template slot-scope="scope">
          <!-- <router-link :to="{path: '/suervey', praram: 'scope.row.id'}" target="_blank"><span class="handle">查看答卷</span></router-link> -->
          <span class="handle" @click="openLook(scope.row.id)">查看答卷</span>
          <span class="handle" @click="downloadAnswer(scope.row.id)">下载答卷</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect"
                       @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="toggleSelection()">全选</div>
        <div class="button" @click="downloadAllAnswers">下载答卷</div>
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.limit"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {timeFormat} from "@/utils/index.js"

const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  company: '',
  name: '',
  admin_id: null,
  area_id: null,
  grid_id: null
}

export default {
  name: 'AnswerSheet',
  data() {
    return {
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      addressData: [],
      dictData: {
        // 地区
        addressData: [],
        gridOptions: [],
        businessManagerOptions: []
      },
    }
  },
  mounted() {
    // this.getData();
    this.getDataList();
    //获取网格列表
    this.$api.Grids().then(res => {
      this.$set(this.dictData, 'gridOptions', res.data)
    })

    //地区
    this.$api.area_tree_list().then(res => {
      this.$set(this.dictData, 'addressData', res.data)
    })

    //商企经理
    this.$api.optionUserList(1).then(res => {
      const data = res.data.map(item => {
        return {
          id: item.id,
          name: item.full_name
        }
      })
      this.$set(this.dictData, 'businessManagerOptions', data)
    })
  },
  methods: {
    openLook(e) {
      let pathInfo = this.$router.resolve({path: '/suervey', query: {id: e}});
      window.open(pathInfo.href, '_blank');
    },
    // getData() {
    //   let data = {
    //     page: 1,
    //     limit: this.limit
    //   }
    //   this.$api.questionnaire_contact_list().then(res => {
    //     this.count = res.data.count;
    //   })
    //   this.$api.questionnaire_contact_list(data).then(res => {
    //     // this.tableData = res.data.list
    //
    //     this.tableData = res.data.list.map(item => {
    //       // 将时间戳格式化
    //       const formattedTime = timeFormat(item.committime, 'yyyy-mm-dd hh:MM:ss');
    //
    //       // 在对象中新增一个属性存储格式化后的时间
    //       return {...item, formattedTime};
    //     });
    //   })
    // },
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleStarChange(index) {
      // this.tableData[index].star = !this.tableData[index].star;
    }, // 单个星标点击
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页limit改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.addressData = []
      this.search();
    },
    /**
     * 获取表格列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.questionnaire_contact_list(this.screen).then(res => {
        console.log(res,144245)
        const tableData = res.data.list.map(item => {
          // 将时间戳格式化
          const formattedTime = timeFormat(item.committime, 'yyyy-mm-dd hh:MM:ss');

          // 在对象中新增一个属性存储格式化后的时间
          return {...item, formattedTime};
        });
        console.log(tableData,1245)
        this.$set(this.tableData, 'data', tableData);
        this.$set(this.tableData, 'total', res.data.count);
        console.log(this.tableData,12)
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    handleChange(value) { //选择地区之后获取网格-
      this.screen.area_id = null;
      if (value) {
        this.screen.area_id = value.map(item => item[item.length - 1]);
      }
    },
    downloadAnswer(id) { //下载答卷
      this.$api.questionnaire_download_question({id: id}).then(res => {
        this.fileDownload(res.data.url)
      })
    },
    downloadAllAnswers() { //下载全部答卷
      const params = {
        ids: this.tableData.ids
      }
      console.log(params, 'params===========')
      this.$api.contact_list(params).then(res => {
        this.fileDownload(res.data.url)
      })
    }
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.answer-sheet {
  margin: 18px;
  padding: 30px 20px;
  background: #FFFFFF;

  .screen {
    padding-bottom: 10px;

    .item {
      float: left;
      margin-bottom: 10px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      span {
        float: left;
        margin-right: 12px;
        font-size: 12px;
        color: #21333F;
        line-height: 34px;
      }

      .box {
        float: left;
      }
    }

    .button {
      float: left;
      margin-left: 10px;
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      color: #6C7880;
      cursor: pointer;
    }

    .inquire {
      color: #FFFFFF;
      background: #3984F5;
    }
  }

  .el-table {
    .star {
      margin-bottom: 2px;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      cursor: default;
    }

    .color-red {
      color: #FD5451;
    }

    .handle {
      margin-right: 20px;
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}
</style>
